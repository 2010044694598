<template>
  <facility-preview-details :entity="this.account" :show-reviews-link="false" />
</template>

<script>
import ProfileMixin from "@/mixins/Profile";

import FacilityPreviewDetails from "@/components/facilities/FacilityPreviewDetails.vue";

export default {
  mixins: [ProfileMixin],
  components: {
    FacilityPreviewDetails,
  },
};
</script>
